export const useFormatPrice = () => {
  const { t } = useI18n()

  const formatNumber = (num: number = 0, options: Intl.NumberFormatOptions = {}) => {
    return num.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...options,
    })
  }

  const formatPrice = (num: number | string, currency: string = 'USD', options: Intl.NumberFormatOptions = {}) => {
    if (typeof num === 'string') {
      num = Number(num)
    }

    if (Number.isNaN(num)) {
      return '0'
    }

    const formattedNumber = formatNumber(Math.abs(num), options)

    return `${num < 0 ? '-' : ''}${t(`symbols.${currency}`)}${formattedNumber}`
  }

  return { formatPrice, formatNumber }
}
